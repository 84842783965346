import * as React from 'react'
import { Helmet } from 'react-helmet'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import './main.css'
import behanceImg from '../images/behance.svg'
import instagramImg from '../images/instagram.svg'
import emailImg from '../images/email.svg'

const contectIconsStyles = {
  width: 30,
  padding: 10,
  background: 'rgba(255, 255, 255, 0.3)',
  borderRadius: 10,
  margin: 4,
}

const announceStyles = {
  margin: '0 5px',
  color: 'rgba(255, 255, 255, 0.8)',
  position: 'absolute',
  bottom: 50,
  fontWeight: 300,
  lineHeight: 1.5,
  '& a, & a:hover': {
    color: 'rgba(255, 255, 255, 0.8) !important',
    textDecoration: 'underline',
  },
}

const whiteLinkStyles = {
  color: 'rgba(255, 255, 255, 0.8)',
  textDecoration: 'underline',
}

const contactStyles = {
  marginRight: 10,
}

const IndexPage = () => (
  <main style={{ maxWidth: 960, padding: 10, margin: 'auto' }}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Lera Swonk</title>
      <link rel="canonical" href="http://leraswonk.com" />
    </Helmet>
    <div style={{ marginTop: '20%' }}>
      <h1
        style={{
          fontSize: '4.5rem',
          fontWeight: 300,
          lineHeight: 1.2,
          marginBottom: '.5rem',
        }}
      >
        LERA SWONK
      </h1>
      <div style={{ display: 'flex' }}>
        <a
          href="https://www.behance.net/leraswonk36a3"
          target="_blank"
          rel="noreferrer"
          style={contactStyles}
        >
          <img src={behanceImg} style={contectIconsStyles} alt="Behance" />
        </a>
        <a
          href="https://instagram.com/leraswonk"
          target="_blank"
          rel="noreferrer"
          style={contactStyles}
        >
          <img src={instagramImg} style={contectIconsStyles} alt="Instagram" />
        </a>
        <a
          href="/cdn-cgi/l/email-protection#177b726576646078797c57707a767e7b3974787a"
          style={contactStyles}
        >
          <img src={emailImg} style={contectIconsStyles} alt="Email" />
        </a>
      </div>
    </div>
    <div style={announceStyles}>
      <h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem' }}>
        <a
          href="https://www.facebook.com/events/2017239961880920/"
          target="_blank"
          rel="noreferrer"
          style={whiteLinkStyles}
        >
          Leraswonk's Coffee Drawings
        </a>
      </h5>
      30 November – 12 December
      <br />
      <b>Home: Coffee & Food</b>
      <br />
      Jonasstraße 23, 12053 Berlin, Germany (
      <a
        href="https://goo.gl/maps/kDqndj3H43M2"
        target="_blank"
        rel="noreferrer"
        style={whiteLinkStyles}
      >
        map
      </a>
      )<br />
    </div>
  </main>
)

export default IndexPage
